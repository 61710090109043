import React from "react"
import {AuthProvider} from "./src/context/auth-context"
import "./src/styles/global.css"
import "./static/assets/css/animate.min.css"
import "./static/assets/css/bootstrap.min.css"
import "./static/assets/css/owl.carousel.min.css"
import "./static/assets/css/owl.theme.default.min.css"
import "./static/assets/css/magnific-popup.css"
import "./static/assets/css/fontawesome-all.min.css"
import "./static/assets/css/swiper.min.css"
import "./static/assets/css/bootstrap-select.min.css"
import "./static/assets/css/tripo-icons.css"
import "./static/assets/css/jquery.mCustomScrollbar.min.css"
import "./static/assets/css/bootstrap-datepicker.min.css"
import "./static/assets/vegas/vegas.min.css"
import "./static/assets/css/nouislider.min.css"
import "./static/assets/css/nouislider.pips.css"
import "./static/assets/css/style.css"
import "./static/assets/css/responsive.css"
import "./static/assets/css/custom.css"
import {LocalizationProvider} from "@mui/x-date-pickers"
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment"
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

export const wrapPageElement = ({element}) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LeTBgIaAAAAADo3QL7X5BQ52VP2D5YqKi8lFs3C">
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <AuthProvider>{element}</AuthProvider>
            </LocalizationProvider>
        </GoogleReCaptchaProvider>
    )
}

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","es","it","de","nl","fr","pt","ro","pl","hu","fi","sv","nn","da"],"defaultLanguage":"en","generateDefaultLanguagePage":true,"siteUrl":"https://find-my-beach.com","trailingSlash":"always","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/post/:uid","getLanguageFromPath":true,"languages":[]},{"matchPath":"/:lang?/blog","getLanguageFromPath":true,"languages":[]},{"matchPath":"/:lang?/blog/(.+)","getLanguageFromPath":true,"languages":[]},{"matchPath":"/email/:lang?","getLanguageFromPath":true,"languages":[]},{"matchPath":"/email/:lang?/(.*)","getLanguageFromPath":true,"languages":[]},{"matchPath":"/:lang?/email","getLanguageFromPath":true,"languages":[]},{"matchPath":"/:lang?/email/(.*)","getLanguageFromPath":true,"languages":[]},{"matchPath":"/about","languages":[]},{"matchPath":"/articles","languages":[]},{"matchPath":"/article/(.*)","languages":[]},{"matchPath":"/404","languages":[]},{"matchPath":"/thankyou","languages":[]},{"matchPath":"/private","languages":[]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Barlow+Condensed","file":"https://fonts.googleapis.com/css?family=Barlow+Condensed:200,300,400,400i,500,600,700,800,900&display=swap"},{"name":"Satisfy","file":"https://fonts.googleapis.com/css?family=Satisfy&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TF93HBNF","includeInDevelopment":false,"enableWebVitalsTracking":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

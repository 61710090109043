import React, { useState, useContext, useEffect } from "react"
import { navigate } from "@reach/router"
import axios from "axios"
const AuthContext = React.createContext()

export const AuthProvider = ({ ...props }) => {
  const [inited, setInited] = useState(false)
  const [me, setMe] = useState(null)
  const [authenticated, setAuthenticated] = useState(false)
  const loopbackUrl = process.env.LOOPBACK_API_URL

  const getMe = async (token) => {
    try {
      const response = await axios.get(`${loopbackUrl}/me`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      if (response.data.id) {
        setAuthenticated(true)
        setMe({ ...response.data, token })
      }
      setInited(true)
    } catch (err) {
      console.error(err)
      setInited(true)
    }
  }

  const login = async (email, password, fn) => {
    let result = { succeed: false, msg: "" }
    try {
      const response = await axios.post(`${loopbackUrl}/users/login`, {
        email,
        password,
      })
      localStorage.setItem("dogToken", response?.data?.token ?? "")
      setAuthenticated(true)
      navigate("/private")
      result.succeed = true
    } catch (err) {
      result.msg = err.message ?? "AuthError"
    }
    if (!result.succeed) {
      console.error("result", result)
    }
    fn(result)
    return result
  }

  const logout = () => {
    setAuthenticated(false)
    setMe(null)
    localStorage.removeItem("dogToken")
    navigate("/private")
  }

  useEffect(() => {
    const token = localStorage.getItem("dogToken")
    if (token) {
      getMe(token)
    } else {
      setInited(true)
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        authenticated,
        me,
        inited,
      }}
      {...props}
    />
  )
}

export const useAuth = () => useContext(AuthContext)

export default AuthContext
